<template>
  <b-row>
    <b-col>
      <b-row class="p-4 bg-mellow mb-3" v-if="event">
        <b-col cols="12">
          <h1 class="h1 mb-0">{{ event.nwpName }}</h1>
        </b-col>
        <b-col cols="12 d-none d-md-block">
          <h3 class="text-muted">{{ formatDate(event.startDate) }} {{ $t(event.nwpCanton) }} - {{ $t(event.venue) }} | {{ $t(event.nwpCategory) }}</h3>
        </b-col>
        <b-col cols="12 d-block d-md-none">
          <h3 class="text-muted">{{ formatDate(event.startDate) }}<br>{{ $t(event.nwpCanton) }} - {{ $t(event.venue) }}<br>{{ $t(event.nwpCategory) }}</h3>
        </b-col>
        <b-col cols="12">
          <b-link :to="`/${$i18n.locale}/resultate/`">{{ $t('resultate_liste.label_zurueck_zur_uebersicht') }}</b-link>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12">
      <b-row>
        <b-col md="6" lg="3">
          <b-form-group>
            <b-form-select
              v-model="selectedFilters.nwpDisciplineCategory"
              :options="selectFields.nwpDisciplineCategory"
              @input="filterTable"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6" lg="3">
          <b-form-group>
            <b-form-select
              v-model="selectedFilters.qualificationsround"
              :options="selectFields.qualificationsround"
              @input="filterTable"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6" lg="3">
          <b-form-group size="s">
            <b-form-input v-model="selectedFilters.name" @keyup="filterTable" :placeholder="$t('resultate_liste.label_nach_name_suchen')"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6" lg="3">
          <b-form-group>
            <b-form-select
              v-model="selectedFilters.kidAccountName"
              :options="selectFields.kidAccountName"
              @input="filterTable"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
    <b-col class="mb-3" v-if="selectedFilters.qualificationsround != 0 || selectedFilters.kidAccountName != 0 || selectedFilters.name != '' || selectedFilters.nwpDisciplineCategory != 0">
      <b-button v-on:click="resetFilter" size="l"
        >{{ $t('resultate_liste.filter_label_filter_zuruecksetzen') }}</b-button
      >
    </b-col>
    <b-col cols="12" v-if="filteredResults.length > 0">
      <b-row v-for="results in filteredResults" :key="results[0]">
        <b-col cols="12" v-if="results[1].length > 0">
          <b-row v-for="round in results[1]" :key="round[0]">
            <b-col cols="12" class="bg-danger text-white p-3">
              <h3 class="py-0 my-0 px-3">{{ round[1][0].discipline }} {{ $t(results[0]) }}: {{ round[0] }}</h3>
            </b-col>
            <b-col v-if="round[1].length > 0" cols="12" class="d-none d-md-block">
              <b-table
                striped
                hover
                fixed
                responsive
                small
                :items="round[1]"
                :fields="visibleResultFields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :primary-key="round[1].id"
                class="mb-0"
              >

                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="field.colStyle"
                  >
                </template>

                <template #head(name)="row">
                  <span v-html="row.label"></span>
                </template>
                <template #cell(name)="row">
                  <span v-if="row.item.kidName != ''" class="font-weight-bold d-block">{{ row.item.kidName }}</span>
                  <span v-if="row.item.kidAccountName != ''" class="d-block">{{ row.item.kidAccountName }}</span>
                </template>
                
                <!-- <template #head(ageGroup)="row">
                  <span v-html="row.label"></span>
                </template>
                <template #cell(ageGroup)="row">
                  <span v-if="row.item.nwpCanton != ''" class="font-weight-bold d-block">{{ row.item.nwpCanton }}</span>
                  <span v-if="row.item.ageGroup != ''" class="d-block">{{ row.item.ageGroup }}</span>
                </template> -->
                
                <template #head(category)="row">
                  <span v-html="row.label"></span>
                </template>
                <template #cell(category)="row">
                  <span v-if="row.item.discipline != ''" class="d-block">{{ row.item.discipline }}</span>
                  <span v-if="row.item.qualificationsround != ''" class="d-block">{{ row.item.qualificationsround }}<span v-if="row.item.nwpLap"> {{ formatNwpLap(row.item.nwpLap) }}</span> </span>
                </template>
                
                <template #cell(round)="row">
                  {{ formatRound(row.item) }}
                </template>
                
              </b-table>
            </b-col>
            <b-col
              v-if="round[1].length > 0"
              cols="12"
              class="d-block d-md-none"
            >
              <b-row v-for="result in round[1]" :key="result.id">
                <b-col cols="12">
                  <hr />
                </b-col>
                <b-col sm="10">
                  <b-row>
                    <b-col cols="12 mb-2">
                      Rang: {{ result.place }}#
                    </b-col>
                    <b-col cols="12 mb-2">
                      <span v-if="result.kidName != ''" class="font-weight-bold d-block">{{ result.kidName }} <span v-if="result.ageGroup != ''" class="font-weight-normal">({{ $t('resultate_liste.table_header_jahrgang') }} {{ result.ageGroup }})</span></span>
                      <span v-if="result.kidAccountName != ''" class="d-block">{{ result.kidAccountName }}</span>
                    </b-col>
                    <b-col cols="12 mb-2">
                      {{ $t('resultate_liste.table_header_ergebnis') }}: {{ result.timeResult }} ({{ result.qualificationsround }})
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" v-if="filteredResults.length < 1 && displayPageLoader == 0">
      <b-alert show variant="danger">{{ $t('resultate_liste.label_keine_ergebnisse_gefunden') }}</b-alert>
    </b-col>
    <div
      v-if="displayPageLoader > 0"
      id="pageLoader"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </b-row>
</template>

<script>
export default {
  computed: {
    visibleResultFields() {
      return this.resultFields.filter(field => field.visible)
    }
  },
  data() {
    return {
      event: null,
      sortBy: "place",
      sortDesc: false,
      selectedFilters: {
        qualificationsround: 0,
        kidAccountName: 0,
        name: "",
        nwpDisciplineCategory: 0,
      },
      selectFields: {
        qualificationsround: [],
        kidAccountName: [],
        nwpDisciplineCategory: [],
        qualificationsroundDefaultElement: [{ value: 0, text: this.$t('resultate_liste.label_alle_runden') }],
        kidAccountNameDefaultElement: [{ value: 0, text: this.$t('resultate_liste.label_alle_vereine') }],
        nwpDisciplineCategoryDefaultElement: [{ value: 0, text: this.$t('resultate_liste.label_alle_kategorien') }],
      },
      resultFields: [
        {
          key: "place",
          label: this.$t('resultate_liste.table_header_rang'),
          sortable: true,
          formatter: "formatPlace",
          sortByFormatted: false,
          colStyle: { "width": "10%" },
          visible: true
        },
        {
          key: "name",
          label: this.$t('resultate_liste.table_header_name'),
          sortable: false,
          colStyle: null,
          visible: true
        },
        {
          key: "ageGroup",
          label: this.$t('resultate_liste.table_header_jahrgang'),
          sortable: false,
          colStyle: { "width": "10%" },
          visible: true
        },
        {
          key: "timeResult",
          label: this.$t('resultate_liste.table_header_ergebnis'),
          sortable: true,
          formatter: "formatResult",
          colStyle: { "width": "12%" },
          visible: true
        },
        {
          key: "round",
          label: this.$t('resultate_liste.table_header_round'),
          sortable: false,
          class: { "width": "12%" },
          visible: false
        },
        {
          key: "nwpDisciplineCategory",
          label: this.$t('resultate_liste.table_header_kategorie'),
          sortable: false,
          formatter: "formatNwpDisciplineCategory",
          colStyle: { "width": "12%" },
          visible: true
        },
        {
          key: "info",
          label: this.$t('resultate_liste.table_header_info'),
          sortable: false,
          colStyle: { "width": "20%" },
          visible: true
        },
      ],
      customOrder: {
        qualificationsroundFilter: [
          'Lov.Sat.Qualificationsround.Final',
          'Lov.Sat.Qualificationsround.SemiFinal',
          'Lov.Sat.Qualificationsround.ForeRun',
          'Lov.Sat.Qualificationsround.TimeRun'
        ]
      },
      results: [],
      filteredResults: [],
      displayPageLoader: 0,
    }
  },
  props: {
    eventId: {
      type: String,
      default: ''
    }
  },
  created() {
    this.getEvent();
    this.getResults();
  },
  methods: {
    mapResults(data) {
      var tmp = [];
      data.forEach((result) => {
        let tmpResult = result;

        tmpResult.originalPlace = result.place;
        tmpResult.discipline = this.$t(result.discipline);
        tmpResult.qualificationsroundKey = result.qualificationsround;
        tmpResult.qualificationsround = this.$t(result.qualificationsround);

        tmp.push(tmpResult);
      });
      return tmp;
    },
    getEvent() {
      this.displayPageLoader++;
      const id = this.eventId;
      this.$store
        .dispatch("wettkampf/getListe")
        .then((resp) => {
          const events = resp.data;
          for (let i = 0; i < events.length; i++) {
            if (events[i].id == id) {
              this.event = events[i];
              break; // Verlassen den for-loop, wenn der passende Event gefunden werden konnte
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.displayPageLoader--;
        });
    },
    getResults() {
      this.displayPageLoader++;

      this.$store
        .dispatch("wettkampf/getResultate", this.eventId)
        .then((resp) => {
          this.results = this.mapResults(resp.data);
          this.results = this.results.map(result => {
            if (!result.place) {
              result.place = false;
            }
            return result;
          })
          // this.filteredResults = this.results;
          this.filteredResults = this.splitResults(this.results);
          this.prepareFilterDropDowns();
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.displayPageLoader--;
        });
    },
    formatDate(dateStr) {
      var date = new Date(dateStr);
      const options = {day: "2-digit", month: "2-digit", year: "numeric"};
      return date.toLocaleDateString('de', options);
    },
    formatResult(result) {
      return result !== "" && result !== undefined && result !== null && result !== false ? result.toFixed(2) : 'DNS/DNF/DSQ';
    },
    formatNwpLap(value) {
      value = value.substring(value.lastIndexOf('.') + 1);
      value = value.replace(/^0+/, '');
      return value;
    },
    formatNwpDisciplineCategory(category) {
      return this.$t(category);
    },
    formatPlace(place) {
      return place !== "" && place !== undefined && place !== null && place !== false && place !== 0 ? place : '';
    },
    formatRound(data) {
      let place = data.originalPlace;
      let nwpLap = data.nwpLap;

      if (place === undefined || place === null || place === '' || nwpLap === undefined || nwpLap === null || nwpLap === '') return '-';

      place = Number(place);
      nwpLap = this.integerToRoman(Number(nwpLap.substr(nwpLap.lastIndexOf(".") + 1)));

      return `${place}./${nwpLap}`;
    },
    integerToRoman(num) {
      if (typeof num !== 'number') 
      return false; 

      var digits = String(+num).split(""),
      key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM","","X","XX","XXX","XL","L","LX","LXX","LXXX","XC","","I","II","III","IV","V","VI","VII","VIII","IX"],
      roman_num = "",
      i = 3;
      while (i--)
      roman_num = (key[+digits.pop() + (i * 10)] || "") + roman_num;
      return Array(+digits.join("") + 1).join("M") + roman_num;
    },
    displayRound() {
      const index = this.resultFields.findIndex((item) => item.key == 'round');
      if (index != -1) this.resultFields[index].visible = true;
    },
    hideRound() {
      const index = this.resultFields.findIndex((item) => item.key == 'round');
      if (index != -1) this.resultFields[index].visible = false;
    },
    prepareFilterDropDowns() {
      if (this.results.length > 0) {
        let nwpDisciplineCategory = this.getUniqueValues(this.results, 'nwpDisciplineCategory');
        let qualificationsround = this.prepareQualificationsroundDropDown(this.results);
        let kidAccountName = this.getUniqueValues(this.results, 'kidAccountName');

        nwpDisciplineCategory = Array.from(nwpDisciplineCategory);
        nwpDisciplineCategory.sort((a, b) => {
          const aSex = a.slice(0,1);
          const bSex = b.slice(0,1);
          const aAge = a.slice(1);
          const bAge = b.slice(1);
          return aSex > bSex ? 1 : (aSex < bSex ? -1 : (Number(aAge) > Number(bAge) ? 1 : (Number(aAge) < Number(bAge) ? -1 : 0)));
        });

        this.selectFields.nwpDisciplineCategory = [].concat(
          this.selectFields.nwpDisciplineCategoryDefaultElement,
          nwpDisciplineCategory
        );

        qualificationsround = qualificationsround.sort((a, b) => {
          if (a.value.qualificationsround > b.value.qualificationsround) {
            return 1
          } else if (a.value.qualificationsround < b.value.qualificationsround) {
            return 0
          } else if (a.value.nwpLap > b.value.nwpLap) {
            return 1
          } else if (a.value.nwpLap < b.value.nwpLap) {
            return 0
          }
        });
        
        this.selectFields.qualificationsround = Array.from(qualificationsround)
        this.selectFields.qualificationsround.sort((a, b) => {
          if (a.value.sort > b.value.sort) {
            return 1
          } else if (a.value.sort < b.value.sort) {
            return -1
          } else if (a.value.nwpLap > b.value.nwpLap) {
            return 1
          } else if (a.value.nwpLap < b.value.nwpLap) {
            return -1
          } else {
            return -1
          }
        });

        this.selectFields.qualificationsround = [].concat(
          this.selectFields.qualificationsroundDefaultElement,
          this.selectFields.qualificationsround, { value: 'gesamtergebnis', text: this.$t('resultate_liste.label_gesamtergebnis') }
        );
        
        kidAccountName = this.sortSet(kidAccountName);
        this.selectFields.kidAccountName = [].concat(
          this.selectFields.kidAccountNameDefaultElement,
          Array.from(kidAccountName)
        );
      }
    },
    prepareQualificationsroundDropDown(array) {
      var result = [];

      array.forEach((item, i) => {
        if (item.hasOwnProperty('qualificationsround') && item.qualificationsround != "") {
          const roundNumber = Number(item.nwpLap.split(".").pop());
          const roundName = this.$t(item.qualificationsround);
          const label = roundNumber != 0 ? `${roundNumber}. ${roundName}` : roundName;

          if (roundNumber != 0) {
            var dropDownItem = {
              value: {
                nwpLap: item.nwpLap,
                qualificationsround: roundName,
                sort: this.customOrder.qualificationsroundFilter.indexOf(item.qualificationsroundKey)
              },
              text: label
            }
          } else {
            var dropDownItem = {
              value: {
                qualificationsround: roundName,
                sort: this.customOrder.qualificationsroundFilter.indexOf(item.qualificationsroundKey)
              },
              text: label
            }
          }

          const itemExists = result.filter(row => row.text == label);
          if (itemExists.length == 0) result.push(dropDownItem);
        }
      });

      return result;
    },
    displayGesamtergebnis() {
      return this.selectedFilters.qualificationsround == 'gesamtergebnis'
    },
    getUniqueValues(array, key) {
      var result = new Set();
      array.forEach((item) => {
          if (item.hasOwnProperty(key) && item[key] != "") {
            result.add(this.$t(item[key]));
          }
      });
      return result;
    },
    filterTable() {
      //reset Filter at the begining
      this.filteredResults = this.results;
      this.hideRound();

      if (this.selectedFilters.name != "") {
        this.filteredResults = this.filteredResults.filter(item => item.kidName.toLowerCase().indexOf(this.selectedFilters.name.toLowerCase()) > -1)
      }

      if (this.selectedFilters.nwpDisciplineCategory != 0) {
        this.filteredResults = this.filteredResults.filter(item => this.$t(item.nwpDisciplineCategory) == this.selectedFilters.nwpDisciplineCategory)
      }
      
      if (this.selectedFilters.qualificationsround != 0) {
        if (!this.displayGesamtergebnis()) {
          this.filteredResults = this.filteredResults.filter((item) => {
            if (this.selectedFilters.qualificationsround.hasOwnProperty('nwpLap') && this.selectedFilters.qualificationsround.hasOwnProperty('qualificationsround')) {
              return item.qualificationsround == this.selectedFilters.qualificationsround.qualificationsround && item.nwpLap == this.selectedFilters.qualificationsround.nwpLap
            } else if (this.selectedFilters.qualificationsround.hasOwnProperty('qualificationsround')) {
              return item.qualificationsround == this.selectedFilters.qualificationsround.qualificationsround && (item.nwpLap === '' || item.nwpLap === undefined || item.nwpLap === null)
            } else {
              return item.qualificationsround == this.selectedFilters.qualificationsround
            }
          })
        } else {
          this.displayRound();
        }
      }
      
      if (this.selectedFilters.kidAccountName != 0) {
        this.filteredResults = this.filteredResults.filter(item => item.kidAccountName == this.selectedFilters.kidAccountName)
      }
      this.filteredResults = this.splitResults(this.filteredResults);
    },
    resetFilter() {
      this.selectedFilters.qualificationsround = 0;
      this.selectedFilters.kidAccountName = 0;
      this.selectedFilters.name = "";
      this.selectedFilters.nwpDisciplineCategory = 0;

      this.filteredResults = this.splitResults(this.results);
    },
    sortSet(set) {
      const entries = [];
      for (const member of set) {
        entries.push(member);
      }
      set.clear();
      for (const entry of entries.sort()) {
        set.add(entry);
      }
      return set;
    },
    splitResults(data) {
      // Split data by categories (ex. M6, M7, W15 etc.)
      var resultsByCategories = {};
      for (let index = 0; index < data.length; index++) {
        if (!Array.isArray(resultsByCategories[data[index].nwpDisciplineCategory])) resultsByCategories[data[index].nwpDisciplineCategory] = [];
        resultsByCategories[data[index].nwpDisciplineCategory].push(data[index]);
      }

      resultsByCategories = Object.entries(resultsByCategories)

      // Split subarray by round (ex. final, semifinal 1, semifinal 2, preround 1, preround 2, preround 3 etc.)
      for (let index = 0; index < resultsByCategories.length; index++) {
        var tmpResultsByRound = {};
        if (this.displayGesamtergebnis()) {
          for (let indexInner = 0; indexInner < resultsByCategories[index][1].length; indexInner++) {
            var qualificationsround = resultsByCategories[index][1][indexInner].qualificationsround;
            if (!Array.isArray(tmpResultsByRound[qualificationsround])) tmpResultsByRound[qualificationsround] = [];
            tmpResultsByRound[qualificationsround].push(resultsByCategories[index][1][indexInner]);
          }
        } else {
          for (let indexInner = 0; indexInner < resultsByCategories[index][1].length; indexInner++) {
            var nwpLap = this.formatNwpLap(resultsByCategories[index][1][indexInner].nwpLap);
            var qualificationsround = resultsByCategories[index][1][indexInner].qualificationsround;
            qualificationsround = nwpLap ? `${qualificationsround} ${nwpLap}` : qualificationsround;
            if (!Array.isArray(tmpResultsByRound[qualificationsround])) tmpResultsByRound[qualificationsround] = [];
            tmpResultsByRound[qualificationsround].push(resultsByCategories[index][1][indexInner]);
          }
        }
        resultsByCategories[index][1] = Object.entries(tmpResultsByRound);
      }

      // Sortiere Daten:
      // - Alle Männer-Klassen (M15, M14, M13 etc.) zuerst, dann alle Frauen-Klassen (W15, W14, W13 etc.)
      // - Innerhalb der Klasse, die ältesten zuerst (15, 14, 13, 12 etc.)
      resultsByCategories.sort((a,b) => {
        try {
          var aSex = a[0].substr(a[0].lastIndexOf(".") + 1).substr(0,1); // Geschlecht: "M" oder "W"
          var aCat = a[0].substr(a[0].lastIndexOf(".") + 1).substr(1); // Alterskategorie: 15, 14, 13 ... 7;
          
          var bSex = b[0].substr(b[0].lastIndexOf(".") + 1).substr(0,1); // Geschlecht: "M" oder "W"
          var bCat = b[0].substr(b[0].lastIndexOf(".") + 1).substr(1); // Alterskategorie: 15, 14, 13 ... 7;
  
          if (aSex == bSex) return aCat == bCat ? 0 : (aCat > bCat ? -1 : 1);
          return aSex > bSex ? 1 : -1;
        } catch (error) {
          return 0;
        }
      });
      
      // Sortiere Daten:
      // - Zuoberst Final dann alle Halbfinals, dann die Vorrunden
      for (let index = 0; index < resultsByCategories.length; index++) {
        if (resultsByCategories[index][1]) {
          resultsByCategories[index][1].sort((a,b) => {
            try {
              return a[0] == b[0] ? 0 : (a[0] > b[0] ? 1 : -1);
            } catch (error) {
              return 0;
            }
          });
        }
      }

      // Sortiere Daten:
      // - Sortiere neu nach Zeit (statt nach Rang) und passe die Ränge anhand der Reihenfolge an
      for (let index = 0; index < resultsByCategories.length; index++) {
        const element = resultsByCategories[index];
        for (let indexInner = 0; indexInner < resultsByCategories[index][1].length; indexInner++) {
          const elementInner = resultsByCategories[index][1][indexInner][1];

          // Sortiere Array nach Zeit
          resultsByCategories[index][1][indexInner][1].sort((a, b) => {
            try {
              if (a.timeResult === undefined) return 1;
              if (b.timeResult === undefined) return -1;
              if (a.timeResult === b.timeResult) return a.info == b.info ? 0 : (a.info < b.info ? -1 : 1);
              return a.timeResult == b.timeResult ? 0 : (a.timeResult < b.timeResult ? -1 : 1);
            } catch (error) {
              return 0;
            }
          })

          // Passe Ränge an
          var previousTime = 0;
          var currentPlace = 1;
          for (let indexInnerInner = 1; indexInnerInner <= resultsByCategories[index][1][indexInner][1].length; indexInnerInner++) {
            const currentTime = resultsByCategories[index][1][indexInner][1][indexInnerInner - 1].timeResult;
            if (currentTime === undefined) continue;
            if (currentTime === previousTime && resultsByCategories[index][1][indexInner][1][indexInnerInner - 1].info == '' && resultsByCategories[index][1][indexInner][1][indexInnerInner - 2].info == '') {
              resultsByCategories[index][1][indexInner][1][indexInnerInner - 1].place = currentPlace - 1;
            } else {
              resultsByCategories[index][1][indexInner][1][indexInnerInner - 1].place = currentPlace;
            }
            previousTime = currentTime;
            currentPlace++;
          }

        }
      }

      return resultsByCategories;
    }
  }
}
</script>

<style lang="scss" scoped>
#pageLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(239, 239, 239, 0.6);
}
</style>